import { environment } from '@env/environment';

function getServerUrl () {
    let url = '';
    if (window.location.origin.includes('localhost')) {
        url = environment.devServerUrl;
    }
    else if (window.location.origin.includes('staging')) {
        url = environment.stagingServerUrl;
    }
    else {
        url = environment.prodServerUrl;
    }
    return url
}

export const serverUrl = getServerUrl()