<div id="docs-wrapper">
  <ion-row class="heading">
    <ion-col size="10">
      <p class="section-title ion-no-margin">{{title}}</p>
      <p class="faded small ion-no-margin"> (png, jpg, docx, xlsx or pdf)</p>
      <!-- <ion-icon name="arrow-forward"></ion-icon> -->
    </ion-col>
    <ion-col class="ion-text-right">
      <div class="doc-div">
        <label [for]="'doc-upload-'+childIndex+'_'+model" class="custom-doc-upload">
          <ion-button id="add-doc-button" [disabled]="!canAddDocs" size="small" fill="clear">
            <ion-icon name="add-circle-outline"></ion-icon>
          </ion-button>
        </label>
        <input [disabled]="!canAddDocs" #docUpload class="hidden" accept=".pdf,.png,.jpg,.jpeg,.docx,.xlsx" [id]="'doc-upload-'+childIndex+'_'+model" type="file"
          (change)="handleDocUpload($event)" />
      </div>
    </ion-col>
  </ion-row>
  <ion-row *ngFor="let doc of docs" class="ion-align-items-center doc-item" [ngClass]="{'doc-item': layout}">
    <ion-col class="field-name" size="10">
      <a [href]="doc.presigned_url" target="_blank">{{doc.name}}</a>
    </ion-col>
    <ion-col class="ion-text-right" size="2">
      <ion-button id="remove-doc-button" [disabled]="!canEditDocs" fill="clear" color="danger" class="delete-button" (click)="deleteFileEvent(doc.id)">
        <ion-icon name="trash"></ion-icon>
      </ion-button>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="uploadLoader">
    <ion-col class="field-name" size="10">Uploading ... 
      <ion-progress-bar type="indeterminate"></ion-progress-bar>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="!docs || !docs.length">
    <ion-col>
      <p class="ion-no-margin faded small">No documents added</p>
    </ion-col>
  </ion-row>
</div>