import { Injectable } from '@angular/core';
import { AuthService } from '@app/global/services/auth.service';
import { GeneralService } from './general.service';
import { SharedVarsService } from './shared-vars.service';
import { ExtraServerSetting } from '@app/global/models/basic';
import { CrudService } from './crud.service';


@Injectable()
export class StartupService {
  constructor(private auth: AuthService, 
    private general: GeneralService,
    private sharedVars: SharedVarsService,
    private crud: CrudService
    ) {}

  /**
   * Get extra server settings at startup.
   * These settings do not require authentication.
   * @returns 
   */
  getServerSettings() {
    return new Promise(async (resolve, reject) => {
      await this.general.getExtraServerSettings().then((res: ExtraServerSetting[]) => {
        this.sharedVars.EXTRA_SERVER_SETTINGS = res;
        this.sharedVars.MAINTENANCE_MODE = res.find((setting: any) => setting.key === 'MAINTENANCE')!.boolean_value || false;
        this.sharedVars.APPROVAL_CHANGE_LIMIT = res.find((setting: any) => setting.key === 'APPROVAL_CHANGE_LIMIT')?.number_value || 2;
        resolve(true);
      })
      .catch((err) => {
        resolve(true);
      })
    });
  }

  /**
   * Get CSRF token at startup.
   */
  getCsrfToken() {
    this.crud.get('csrf').then((res) => {
    })
  }
}
