import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from '@app/global/components/header/header.component';
import { ItemOptionsComponent } from '@app/global/components/item-options/item-options.component';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    IonicModule,
    ItemOptionsComponent
  ],
  exports: [HeaderComponent]
})
export class HeaderModule { }
