<div *ngIf="inventoryOutServ.selectedInvOutRequest && inventoryOutServ.selectedInvOutRequest.id">
  <ion-row>
    <ion-col class="field-name ion-text-left" size="4">Project</ion-col>
    <ion-col class="field-value ion-text-left">{{inventoryOutServ.selectedInvOutRequest.project?.name}}</ion-col>
  </ion-row>
  <ion-row>
    <ion-col class="field-name ion-text-left" size="4">Inventory Out Request raised by</ion-col>
    <ion-col class="field-value ion-text-left">{{inventoryOutServ.selectedInvOutRequest.created_by.name}}</ion-col>
  </ion-row>
  <ion-row>
    <ion-col class="field-name ion-text-left" size="4">Inventory Out Request approved by</ion-col>
    <ion-col class="field-value ion-text-left">{{inventoryOutServ.selectedInvOutRequest.approval?.redirected_to ? inventoryOutServ.selectedInvOutRequest.approval?.redirected_to?.name : inventoryOutServ.selectedInvOutRequest.approval.approver.name}}</ion-col>
  </ion-row>
</div>
<ion-row>
  <ion-col class="ion-text-left">
    <h4>{{props.label}}</h4>
  </ion-col>
  <ion-col class="ion-text-right">
    <ion-button disabled (click)="addItem()" color="success">Add Item</ion-button>
  </ion-col>
</ion-row>
<ion-row *ngFor="let invOutReqItem of field.fieldGroup; let i = index" >
  <ion-col>
    <ion-row class="ion-align-items-center">
      <ion-col class="ion-text-left">
        <ion-text color="medium">Item {{ i + 1 }}</ion-text>
      </ion-col>
      <ion-col class="ion-text-right">
        <ion-button *ngIf="inventoryOutServ.showDeleteButton" (click)="removeItem(i)" color="danger" fill="clear">
          <ion-icon name="trash"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row class="quote-item">
      <ion-row *ngIf="inventoryOutServ.selectedInvOutRequestItem.length && inventoryOutServ.selectedInvOutRequestItem[i].id" class="ion-no-padding read-only-details">
        <ion-col class="ion-no-padding left-section">
          <ion-row>
            <ion-col class="section-title ion-text-left">Inventory-out-request Item Details
            </ion-col>
          </ion-row>
          <div *ngIf="inventoryOutServ.selectedInvOutRequestItem[i].material">
            <ion-row>
              <ion-col class="field-name" size="5">Material</ion-col>
              <ion-col class="field-value clickable link ion-text-left">{{inventoryOutServ.selectedInvOutRequestItem[i].material.name}}</ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="field-name" size="5">MMID</ion-col>
              <ion-col class="field-value clickable link ion-text-left">{{inventoryOutServ.selectedInvOutRequestItem[i].material_request_item?.mmid?.name}}</ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="field-name" size="5">Vendor</ion-col>
              <ion-col class="field-value clickable link ion-text-left">{{inventoryOutServ.selectedInvOutRequestItem[i].material_request_item?.vendor?.name}}</ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="field-name" size="5">Quantity</ion-col>
              <ion-col class="field-value ion-text-left">{{inventoryOutServ.selectedInvOutRequestItem[i].quantity}}
                {{inventoryOutServ.selectedInvOutRequestItem[i].material.unit}}</ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="field-name" size="5">Status</ion-col>
              <ion-col class="field-value ion-text-left">
                <p class="ion-no-margin">Issued: {{inventoryOutServ.selectedInvOutRequestItem[i].issued}} {{inventoryOutServ.selectedInvOutRequestItem[i].material.unit}}</p>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="field-name" size="5">Remaining reserved</ion-col>
              <ion-col class="field-value ion-text-left">
                <p class="ion-no-margin">{{inventoryOutServ.selectedInvOutRequestItem[i]?.stock?.reserved}} {{inventoryOutServ.selectedInvOutRequestItem[i].material.unit}}</p>
              </ion-col>
            </ion-row>
          </div>
          <div *ngIf="inventoryOutServ.selectedInvOutRequestItem[i].part">
            <ion-row>
              <ion-col class="field-name" size="5">Part</ion-col>
              <ion-col class="field-value clickable link ion-text-left">{{inventoryOutServ.selectedInvOutRequestItem[i].part.name}}</ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="field-name" size="5">Quantity</ion-col>
              <ion-col class="field-value ion-text-left">{{inventoryOutServ.selectedInvOutRequestItem[i].quantity}}
                {{inventoryOutServ.selectedInvOutRequestItem[i].part.unit}}</ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="field-name" size="5">Status</ion-col>
              <ion-col class="field-value ion-text-left">
                <p class="ion-no-margin">Issued: {{inventoryOutServ.selectedInvOutRequestItem[i].issued}} {{inventoryOutServ.selectedInvOutRequestItem[i].part.unit}}</p>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="field-name" size="5">Remaining reserved</ion-col>
              <ion-col class="field-value ion-text-left">
                <p class="ion-no-margin">{{inventoryOutServ.selectedInvOutRequestItem[i]?.stock?.reserved}} {{inventoryOutServ.selectedInvOutRequestItem[i].part.unit}}</p>
              </ion-col>
            </ion-row>
          </div>
        </ion-col>
      </ion-row>
      <ion-col>
        <formly-field [field]="invOutReqItem"></formly-field>
      </ion-col>
    </ion-row>
  </ion-col>
</ion-row>
