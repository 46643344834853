import { Component, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'app-purchase-order-item',
  templateUrl: './purchase-order-item.component.html',
  styleUrls: ['./purchase-order-item.component.scss'],
})
export class PurchaseOrderItemComponent extends FieldArrayType implements OnInit {

  ngOnInit() {}

  removeField(index: number) {

  }
}
