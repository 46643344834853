import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/global/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from '@app/global/services/session.service';
import { NotificationsService } from '@app/global/services/notifications.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  
  constructor(
    private auth: AuthService,
    private router: Router,
    private sessionServ: SessionService,
    public notifServ: NotificationsService
  ) {}

  ngOnInit() {}
  goToInbox(){
    this.router.navigate(['inbox'], { replaceUrl: true });
  }
  logout() {
    this.auth.logout().then((res) => {
      this.sessionServ.stopSession();
    })
    .catch((err) => {
      console.log(err);
    });
  }
}
