import { FieldArrayType, FormlyFormBuilder } from '@ngx-formly/core';
import { Component, OnInit } from '@angular/core';
import { AlertsService } from '@app/global/services/alerts.service';
import { QuoteService } from '@app/global/services/quote.service';
import { Document } from '@app/global/models/basic';

@Component({
  selector: 'app-quote-line-item',
  templateUrl: './quote-line-item.component.html',
  styleUrls: ['./quote-line-item.component.scss'],
})
export class QuoteLineItemComponent extends FieldArrayType implements OnInit {
  docsFieldIndex:number; // This value will be updated once a quote is added
  constructor(private alerts: AlertsService, public quoteServ: QuoteService) {
    super();
  }

  ngOnInit() {
    // find index of docs field in the fieldGroup
    this.whichElementIsDocsField();
  }

  whichElementIsDocsField() {
    if(this.docsFieldIndex===undefined && this.field.fieldGroup?.length && this.field.fieldGroup[0].fieldGroup?.length){
      this.docsFieldIndex = this.field.fieldGroup[0].fieldGroup.findIndex((field: any) => field.key === 'docs');
    }
  }

  addQuote() {
    QuoteService.TOTAL_QUOTES += 1;
    this.add();
    this.whichElementIsDocsField();
  }

  /**
   * Removes the document from the quote
   * @param id - Id of the document to be removed
   * @param quoteIndex - Index of the quote in the fieldGroup
   */
  removeDocument(id: number, quoteIndex: any) {
    const quotesDocs = this.field.fieldGroup![quoteIndex].fieldGroup![this.docsFieldIndex].formControl?.value;
    if (quotesDocs && quotesDocs.length) {
      const updatedDocs = quotesDocs.filter((doc: Document) => doc.id !== id);
      this.field.fieldGroup![quoteIndex].fieldGroup![this.docsFieldIndex].formControl?.setValue(updatedDocs);
    }
  }

  /**
   * Receives the file upload event from the file upload component and updates the docs list
   * @param event - Object received from the file upload component
   * @param quoteIndex - Index of the quote in the fieldGroup
   */
  onFileSelected(event: Document, quoteIndex: number) {
    if (event) {
      const quotesDocs = this.field.fieldGroup![quoteIndex].fieldGroup![this.docsFieldIndex].formControl?.value;
      let docUrls = [];
      if (quotesDocs && quotesDocs.length) {
        docUrls = [...quotesDocs, event];
      } else {
        docUrls = [event];
      }
      this.field.fieldGroup![quoteIndex].fieldGroup![this.docsFieldIndex].formControl?.setValue(docUrls);  
    }
  }

  /**
   * Removes the quote from the fieldGroup after confirmation
   * @param i - Index of the quote in the fieldGroup
   */
  async removeField(i: number) {
    QuoteService.TOTAL_QUOTES -= 1;
    const decision = await this.alerts.showPromisableAlert(
      'Remove Quote',
      'Are you sure you want to remove this Quote from the request?',
      true,
      'Remove',
      'Cancel',
      'danger',
      'medium'
    );
    if (decision) {
      this.remove(i);
    }
  }
}
