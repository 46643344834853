import { Injectable } from '@angular/core';
import { SharedVarsService } from '@app/global/services/shared-vars.service';
import { CrudService } from './crud.service';
import { BehaviorSubject } from 'rxjs';
import { ReturnableForm, ReturnableFormData } from '../models/formly-forms';
import { Returnable } from '../models/basic';

@Injectable({
  providedIn: 'root',
})
export class InventoryOutService {
  public isInvOutReqSelected = new BehaviorSubject(false);
  public showDeleteAlert = false;
  public showDeleteButton = true;
  public selectedInventoryOut = 0;
  public selectedInvOutRequestItem: any = [];
  public selectedInvOutRequest: any = {};
  constructor(
    private sharedVars: SharedVarsService,
    private crud: CrudService
  ) {}

  getInventoryOutById(invOutId: number) {
    return new Promise((resolve, reject) => {
      this.crud
        .get('inventoryout', invOutId)
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAllInventoryOuts(
    page: number,
    sortText?: string,
    searchText?: string,
    filters?: any[], 
  ) {
    return new Promise((resolve, reject) => {
      let params:any = {
        ordering: sortText,
        search: searchText 
      }
      if (filters && filters.length > 0) {
        filters.forEach((filter) => {
          params[filter.key] = filter.value;
        });
      }
      this.crud
        .list('inventoryout', page, params)
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createInventoryOut(inventoryOutPayload: any) {
    return new Promise((resolve, reject) => {
      this.crud
        .post('inventoryout', inventoryOutPayload)
        .then((res) => {
          if (res.status === 201) {
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteInventoryOut(inventoryOutId: number) {
    return new Promise((resolve, reject) => {
      this.crud
        .patch('inventoryout', inventoryOutId, { is_deleted: true })
        .then((res) => {
          if (res.status === 201) {
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateInventoryOut(inventoryOutId: number, inventoryOutData: any) {
    return new Promise((resolve, reject) => {
      this.crud
        .patch('inventoryout', inventoryOutId, inventoryOutData)
        .then((res) => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  cancelInventoryOut(inventoryOutId: number) {
    return this.updateInventoryOut(inventoryOutId, { is_canceled: true });
  }

  getAllReservedInventoryForMaterialRequestItem(materialRequestItemId: number, available: boolean = false) {
    return new Promise((resolve, reject) => {
      this.crud
        .get('reserved', '', { material_request_item: materialRequestItemId, available: available })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createReturnable(payload: ReturnableFormData){
    return new Promise((resolve, reject)=>{
      this.crud.post('returnables', payload)
      .then((res)=>{
        if(res.status === 201){
          resolve(res);
        }
      })
      .catch((err)=>{
        reject(err);
      })
    })
  }

  updateReturnable(id: number, payload: ReturnableForm){
    return new Promise((resolve, reject)=>{
      this.crud.put('returnables', id, payload)
      .then((res)=>{
        if(res.status === 200){
          resolve(res);
        }
      })
      .catch((err)=>{
        reject(err);
      })
    })
  }
}
