import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { PopoverController } from '@ionic/angular';
import { SelectSearchComponent } from '@app/global/components/select-search/select-search.component';
import { AlertsService } from '@app/global/services/alerts.service';
@Component({
  selector: 'app-custom-search-select',
  templateUrl: './custom-search-select.component.html',
  styleUrls: ['./custom-search-select.component.scss'],
})
export class CustomSearchSelectComponent extends FieldType<FieldTypeConfig> implements OnInit {
  selectedOption: any;

  constructor(private popoverController: PopoverController,
    private alerts: AlertsService) {
    super();
  }

  ngOnInit(): void {
    if (this.formControl?.value) {
      this.selectedOption = (this.props.options as any[]).find((option: any) => option.value === this.formControl.value)?.label;
    }
    this.formControl.valueChanges.subscribe((updatedFieldValue) => {
      if (updatedFieldValue === null) {
        this.selectedOption = '';
      }
      else{
        this.selectedOption = (this.props.options as any[]).find((option: any) => option.value === updatedFieldValue)?.label;
      }
    });
  }

  async openPopOver(ev: any) {
    if (this.props['dependent'] && this.selectedOption){
      ev.stopPropagation();
      return;
    }
    this.formControl.markAsTouched();
    const popover = await this.popoverController.create({
      component: SelectSearchComponent,
      event: ev,
      translucent: false,
      cssClass: 'select-search-popover',
      componentProps: {
        title: this.props.label,
        items: this.props.options,
        selected: this.selectedOption
      }
    });
     
    await popover.present();
    
    const { data } = await popover.onDidDismiss();
    
    if (data) {
      this.selectedOption = data?.selectedItem?.label;
      this.formControl.setValue(data?.selectedItem?.value);
      this.formControl.markAsDirty();
    }
  }

  /**
   * Called on 'X' button click to clear the selected option.
   * Clears the form control value.
   * If the dependent field has values, shows a confirmation alert.
   * @param e 
   */
  clearSelection(e: any){
    e.stopPropagation();
    const dependentField = this.props['dependentField'];
    if (this.formControl?.parent?.value[dependentField]?.length > 0) {
      this.alerts.showPromisableAlert(
        'Clear Selection',
        `Clearing the ${this.props.label} will remove all items from the form. Are you sure you want to continue?`,
        true,
        'Clear',
        'Cancel',
        'danger',
        'medium'
      ).then((decision) => {
        if (decision) {
          this.formControl.setValue('');
          this.selectedOption = '';
        }
      }
      )
    }else{
      this.formControl.setValue('');
      this.selectedOption = '';
    }
  }
}
