import { Component, OnInit } from '@angular/core';
import { AlertsService } from '@app/global/services/alerts.service';
import { FieldArrayType } from '@ngx-formly/core';
import { SharedVarsService } from '@app/global/services/shared-vars.service';
import { FormlyFieldsService } from '@app/global/services/formly-fields.service';
@Component({
  selector: 'app-purchase-request-item',
  templateUrl: './purchase-request-item.component.html',
  styleUrls: ['./purchase-request-item.component.scss'],
})
export class PurchaseRequestItemComponent extends FieldArrayType {
  constructor(private alerts: AlertsService,
    public sharedVars: SharedVarsService,
    private formServ: FormlyFieldsService) {
    super();
  }
  ngOnInit() {}
  async removeField(i: number) {
    const decision = await this.alerts.showPromisableAlert(
      'Delete Material',
      'Are you sure you want to remove this item from the request?',
      true,
      'Remove',
      'Cancel',
      'danger',
      'medium'
    );
    if (decision) {
      this.remove(i);
    }
  }
}
