import { Injectable } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { SESSION_CONSTANTS } from '@app/global/constants/constants';
import { AuthService } from './auth.service';
import { AlertsService } from './alerts.service';
import { SharedVarsService } from './shared-vars.service';
import { Keepalive } from '@ng-idle/keepalive';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  subscription: any;
  constructor(
    private idle: Idle,
    private authServ: AuthService,
    private alerts: AlertsService,
    private keepalive: Keepalive
  ) {
    this.idle.setIdle(SESSION_CONSTANTS.SESSION_IDLE_TIMEOUT);
    this.idle.setTimeout(SESSION_CONSTANTS.SESSION_WARNING_TIMEOUT);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onTimeout.subscribe(() => {
      this.handleSessionTimeout();
    });
    idle.onIdleStart.subscribe(() => {
      this.warnUser();
    });
    // sets the ping interval to 15 seconds
    this.keepalive.interval(600);

    this.keepalive.onPing.subscribe(() => {
      this.keepAliveAction();
    });

  }

  keepAliveAction() {
    this.authServ.ping();
  }

  /**
   * This method will be called when the session is timed out.
   */
  handleSessionTimeout() {
    // this.stopSession();
    this.alerts.closeAlert();
    this.alerts.dismissModal();
    this.alerts.dismissToast();
    this.authServ.logout();
    this.alerts.showToast('Your session has expired, please login again to continue.', 'bottom', 0, 'medium');
    this.subscription = document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        setTimeout(() => {
          // Tab is visible and logout has happened, close the alert
          this.alerts.closeAlert();
          this.alerts.dismissModal();
          this.alerts.dismissToast();
          this.unsubscribe();
        }, 100);
      }
    });
  }
  unsubscribe() {
    document.removeEventListener('visibilitychange', this.subscription);
  }

  /**
   * This method will show a warning to the user that his session is about to expire.
   * If the user clicks on continue, the session will be continued.
   * If the user clicks on logout, the session will be stopped.
   */
  async warnUser() {
    const decision = await this.alerts.showPromisableAlert(
      'Session Inactive',
      `Your session will expire in ${SESSION_CONSTANTS.SESSION_WARNING_TIMEOUT} seconds, do you want to continue ?`,
      true,
      'Continue',
      'Logout',
      'success',
      'danger'
    );
    if (decision) {
      // this.authServ.ping(); // Keepalive pings on reset
      this.startSession();
    } else {
      this.handleSessionTimeout();
    }
  }

  stopSession() {
    this.idle.stop();
  }

  /**
   * The API call is essentially just to restart session on backend.
   * If the user is not logged in, the session will not be started.
   * Can be replaced with a ping to the backend.
   */
  startSession() {
    this.idle.watch();
  }
}
