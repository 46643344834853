
<ion-item lines="none">
  <ion-label slot="start">{{props.label}}</ion-label>
  <ion-button slot="end" (click)="addItem()" color="success" size="small">Add</ion-button>
</ion-item>
<ion-item lines="none" *ngFor="let person of field.fieldGroup; let i = index">
  <ion-button slot="start" (click)="removeItem(i)" color="danger" fill="clear">
    <ion-icon name="trash"></ion-icon>
  </ion-button>
  <ion-label class="sub-item" slot="start">Executive {{ i + 1 }}</ion-label>
  <formly-field slot="end" [field]="person"></formly-field>
</ion-item>
