import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AlertsService } from '@app/global/services/alerts.service';
import { InventoryOutService } from '@app/global/services/inventory-out.service';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'app-inventory-out-lineitem',
  templateUrl: './inventory-out-lineitem.component.html',
  styleUrls: ['./inventory-out-lineitem.component.scss'],
})
export class InventoryOutLineItemComponent extends FieldArrayType implements OnInit {
  isInvOutReqSelected = false;
  constructor(
    public inventoryOutServ: InventoryOutService,
    private ref: ChangeDetectorRef,
    private alerts: AlertsService,
    ) {
    super();
  }
  ngOnInit() {
    this.inventoryOutServ.isInvOutReqSelected.subscribe((updatedValue) => {
      this.isInvOutReqSelected = updatedValue;

      // If the Inventory Out Request is cleared and there are items in the selectedInvOutRequestItem array, clear the array
      if(!this.isInvOutReqSelected && this.inventoryOutServ.selectedInvOutRequestItem.length > 0){
        this.inventoryOutServ.selectedInvOutRequestItem = [];
        if(this.field.fieldGroup){
          const fieldGroupLength = this.field.fieldGroup.length;
          for (let i = 0; i < fieldGroupLength; i++) {
            this.remove(0);
          }
        }
      }
      this.ref.detectChanges();
    });
  }

  addItem() {
    this.inventoryOutServ.selectedInvOutRequestItem.push({});
    this.add();
  }

  removeItem(itemIndex: number) {
    if (this.inventoryOutServ.showDeleteAlert) {
      this.showDeleteItemPrompt(itemIndex);
    } else {
      this.removeField(itemIndex);
    }
  }

  async removeField(i: number) {
    const decision = await this.alerts.showPromisableAlert(
      'Remove Item',
      'Are you sure you want to remove this item from the request?',
      true,
      'Remove',
      'Cancel',
      'danger',
      'medium'
    );
    if (decision) {
      this.inventoryOutServ.selectedInvOutRequestItem.splice(i, 1);
      this.remove(i);
    }
  }

  async showDeleteItemPrompt(itemIndex: number) {
    const decision = await this.alerts.showPromisableAlert(
      'Delete Item',
      'Deleting this item will remove the quantity from records, and mark the related Purchase item undelivered. Are you sure you want to remove this item ?',
      true,
      'Delete',
      'Cancel',
      'danger',
      'medium'
    );
    if (decision) {
      this.inventoryOutServ.deleteInventoryOut(this.inventoryOutServ.selectedInventoryOut).then(() => {
        this.alerts.showToast('Deleted the Inventory In Succesfully');
        this.inventoryOutServ.selectedInvOutRequestItem = [];
      });
    }
  }

  ngOnChanges() {
  }

}