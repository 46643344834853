<ion-header>
  <ion-toolbar>
    <ion-title>{{title}}</ion-title>
  </ion-toolbar>
  <ion-toolbar>
    <ion-searchbar animated="true" mode="ios" (ionChange)="filterResults($event)" ></ion-searchbar>
  </ion-toolbar>
</ion-header>
<ion-content>
    <ion-list>
      <ion-item class="ion-border clickable" *ngFor="let item of itemsVisible" (click)="selectItem(item)" [disabled]="item.disabled" [tp]="tpl" [tpDelay]="700">
          <ion-label [ngClass]="{'selected': selected && selected.toLowerCase()===item.label.toLowerCase()}">
            <h2> {{ item?.label }}</h2>
            <p *ngIf="item?.subLabel"> {{ item?.subLabel }} </p>
            <p class="white-space-pre" *ngIf="item?.subLabel2"> {{ item?.subLabel2 }} </p>
          </ion-label>

          <ng-template #tpl let-hide>
            <ion-label>
              <h2> {{ item?.label }}</h2>
              <p *ngIf="item?.subLabel"> {{ item?.subLabel }} </p>
              <p class="white-space-pre" *ngIf="item?.subLabel2"> {{ item?.subLabel2 }} </p>
            </ion-label>
          </ng-template>
      </ion-item>
      <ion-item class="ion-border" *ngIf="!itemsVisible.length" >
        <ion-label class="gray"> 
          <p class="gray">No item found</p> 
          <!-- <ion-button size="small" fill="clear" color="primary" (click)="createItem()">Create</ion-button> -->
        </ion-label>
      </ion-item>
    </ion-list>
</ion-content>
