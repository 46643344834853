<ion-row>
  <ion-col class="ion-text-left">
    <h4>{{ props.label }}</h4>
  </ion-col>
</ion-row>
<ion-row *ngFor="let _field of field.fieldGroup; let i = index">
  <ion-col>
    <ion-row>
      <ion-col>
        <formly-field [field]="_field"></formly-field>
      </ion-col>
    </ion-row>
  </ion-col>
</ion-row>
