import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ItemOption } from '@app/global/models/basic';
import { IonicModule, PopoverController } from '@ionic/angular';

@Component({
  standalone: true,
  imports: [CommonModule, IonicModule],
  selector: 'item-options',
  templateUrl: './item-options.component.html',
  styleUrls: ['./item-options.component.scss'],
})
export class ItemOptionsComponent implements OnInit {
  @Input() options: ItemOption[];
  @Input() showOptions: boolean[];
  @Output() optionClick = new EventEmitter();
  constructor(private popoverController: PopoverController) {}

  /**
   * If no showOptions conditions are given,
   * show all options
   */
  ngOnInit() {
    if (!this.showOptions) {
      this.showOptions = [];
      this.options.forEach((element) => {
        this.showOptions.push(true);
      });
    }
  }
  async setOption(choice: ItemOption) {
    this.optionClick.emit({ choice: choice });
    await this.popoverController.dismiss();
  }
}
