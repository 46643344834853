import { ChangeDetectorRef, Component, OnInit, SimpleChange } from '@angular/core';
import { AlertsService } from '@app/global/services/alerts.service';
import { FieldArrayType } from '@ngx-formly/core';
import { InventoryOutRequestService } from '@app/global/services/inventory-out-request.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-request-line-item',
  templateUrl: './request-line-item.component.html',
  styleUrls: ['./request-line-item.component.scss'],
})
export class RequestLineItemComponent extends FieldArrayType {
  selectedProject: number;
  selectedType: string = '';
  itemSubtexts = [];
  projectSelectedSubscription: Subscription;
  partOrMaterialSubscription: Subscription;
  constructor(private alerts: AlertsService,
    private invOutReqServ: InventoryOutRequestService,
    private ref: ChangeDetectorRef,) {
    super();
  }
  ngOnInit() {
    this.projectSelectedSubscription = this.invOutReqServ.selectedProject.subscribe((updatedValue) => {
      this.selectedProject = updatedValue;

      // If the Project is cleared and there are items in the inv out item array, clear the array
      if(!this.selectedProject){
        if(this.field.fieldGroup){
          const fieldGroupLength = this.field.fieldGroup.length;
          for (let i = 0; i < fieldGroupLength; i++) {
            this.remove(0);
          }
        }
      }
      // this.ref.detectChanges();
    });

    this.partOrMaterialSubscription = this.invOutReqServ.selectedType.subscribe((type) => {
      this.selectedType = type;

      // If the value is cleared/changed and there are items in the item array, clear the array
      if(!this.selectedType){
        if(this.field.fieldGroup && this.field.fieldGroup.length > 0){
          const fieldGroupLength = this.field.fieldGroup.length;
          for (let i = 0; i < fieldGroupLength; i++) {
            this.remove(0);
          }
        }
      }
    });

  }

  ngOnDestroy() {
    if(this.projectSelectedSubscription){
      this.projectSelectedSubscription.unsubscribe();
    }
    if(this.partOrMaterialSubscription){
      this.partOrMaterialSubscription.unsubscribe();
    }
  }
  async addItem() {
    if(!this.invOutReqServ.itemDataFetched){
      await this.invOutReqServ.fetchItemData();
    }
    await this.invOutReqServ.updateItemFormFields(this.field);
    this.add();
  }
  async removeField(i: number) {
    const decision = await this.alerts.showPromisableAlert(
      'Delete Material',
      'Are you sure you want to remove this item from the request?',
      true,
      'Remove',
      'Cancel',
      'danger',
      'medium'
    );
    if (decision) {
      this.remove(i);
    }
  }
}
