import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AlertsService } from '@app/global/services/alerts.service';
import { InventoryInService } from '@app/global/services/inventory-in.service';
import { FieldArrayType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-inventory-in-lineitem',
  templateUrl: './inventory-in-lineitem.component.html',
  styleUrls: ['./inventory-in-lineitem.component.scss'],
})
export class InventoryInLineItemComponent extends FieldArrayType implements OnInit {
  isPoSelected = false;
  poSubscription: Subscription;
  constructor(
    public inventoryInServ: InventoryInService,
    private alerts: AlertsService,
    private ref: ChangeDetectorRef
    ) {
    super();
  }
  ngOnInit() {
    this.poSubscription = this.inventoryInServ.isPoSelected.subscribe((updatedValue) => {
      this.isPoSelected = updatedValue;

      // If the PO is cleared and there are items in the isPoSelected array, clear the array
      if(!this.isPoSelected && this.inventoryInServ.selectedPoItem.length > 0){
        this.inventoryInServ.selectedPoItem = [];
        if(this.field.fieldGroup){
          const fieldGroupLength = this.field.fieldGroup.length;
          for (let i = 0; i < fieldGroupLength; i++) {
            this.remove(0);
          }
        }
      }
      this.ref.detectChanges();

    });
  }

  ngOnDestroy() {
    if(this.poSubscription){
      this.poSubscription.unsubscribe();
    }
  }

  addItem() {
    this.inventoryInServ.selectedPoItem.push({});
    this.inventoryInServ.showHideBatchFields(this.field);
    this.add();
  }

  removeItem(itemIndex: number) {
    this.removeField(itemIndex);
  }

  async removeField(i: number) {
    const decision = await this.alerts.showPromisableAlert(
      'Remove Item',
      'Are you sure you want to remove this item from the request?',
      true,
      'Remove',
      'Cancel',
      'danger',
      'medium'
    );
    if (decision) {
      this.inventoryInServ.selectedPoItem.splice(i, 1);
      this.remove(i);
    }
  }

  ngOnChanges() {
  }

}