<ion-row>
  <ion-col class="ion-text-left">
    <h4>{{ props.label }}</h4>
  </ion-col>
  <ion-col class="line-item-add-button-div">
    <!-- <ion-button (click)="add()" color="success" disabled>Add Item</ion-button>
    <ion-icon name="information-circle-outline" 
    color="medium"
    [tp]="'Since this purchase request is created from a material-request, additional items cannot be added.'" 
    [tpDelay]="700"></ion-icon> -->
  </ion-col>
</ion-row>
<ion-row *ngFor="let _field of field.fieldGroup; let i = index">
  <ion-col>
    <div class="item-wrapper">
      <div class="item-number"><ion-text color="medium">{{ i + 1 }}</ion-text></div>
      <div class="field-wrapper">
        <formly-field class="inline-field" [field]="_field"></formly-field>
      </div>
      <ion-button (click)="removeField(i)" color="danger" fill="clear">
        <ion-icon name="trash"></ion-icon>
      </ion-button>
    </div>
  </ion-col>
</ion-row>
