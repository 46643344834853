import { Component, ElementRef, ViewChild } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-file',
  templateUrl: './file-type.component.html',
  styleUrls: ['../../../../global/components/docs-wrapper/docs-wrapper.component.scss']
})
export class FileTypeComponent extends FieldType<FieldTypeConfig> {
  @ViewChild('filedocUpload', { static: false }) DocFileInputVariable: ElementRef;
  constructor() {
    super();
  }

  onFileSelected(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files[0]) {
      // Access the selected file
      const selectedFile = inputElement.files[0];
      
      // You can also update the form control value if needed

      this.field.formControl.setValue(selectedFile)
      // this.formControl.setValue(selectedFile);
    }
  }
}