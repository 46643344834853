import { Component, OnInit } from '@angular/core';
import { IonicModule, MenuController } from '@ionic/angular';
import { SharedVarsService } from '@app/global/services/shared-vars.service';
import { LoginForm, ResetPasswordRequestModel } from '@app/global/models/formly-forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/global/services/auth.service';
import { AlertsService } from '@app/global/services/alerts.service';
import { SessionService } from '@app/global/services/session.service';
import { FormlyFieldsService } from '@app/global/services/formly-fields.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ExtraServerSetting } from '@app/global/models/basic';
import { ExtraServerSettingKeys } from '@app/global/models/enums';
import { DEFAULT_REDIRECT_TO } from '@app/global/constants/constants';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CustomFormModule } from '@app/global/modules/custom-form/custom-form.module';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CustomFormModule],
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  showPage: boolean = false;
  loginFormModel: LoginForm = {} as LoginForm;
  loginFormData: FormlyFieldConfig[] = [];
  resetPasswordFormModel: ResetPasswordRequestModel = {} as ResetPasswordRequestModel;
  resetPasswordFormData: FormlyFieldConfig[] = [];
  showLoginForm: boolean = true;
  showResetPasswordForm: boolean = false;
  constructor(
    private menuCtrl: MenuController,
    public sharedVars: SharedVarsService,
    private router: Router,
    public auth: AuthService,
    private alerts: AlertsService,
    private sessionServ: SessionService,
    private formServ: FormlyFieldsService
  ) {}

  async ngOnInit() {
    this.alerts.closeAlert();

    try{ // if logged in, redirect to projects page
      await this.auth.checkAuth(this.auth.redirectUrl);
      this.router.navigate([`/${DEFAULT_REDIRECT_TO}`], { replaceUrl: true });
    }
    catch(err){ // if not logged in, show login page
      this.sessionServ.stopSession();
      this.showPage = true;
      this.menuCtrl.enable(false);
      this.loginFormData = [this.formServ.emailField, this.formServ.passwordField];
      this.resetPasswordFormData = [this.formServ.emailField];
    }
  }
  login() {
    this.auth.login(this.loginFormModel).then((res) => {
      this.sessionServ.startSession();
      this.alerts.dismissToast(); // if any
      this.checkGeneralNotice();
      this.checkUserNotice();
      this.redirect();
    })
    .catch((err) => {
      this.alerts.showApiErrorToast(err, 'Invalid username or password');
    });
  }
  /**
   * Redirect to the requested page after login.
   * If the redirect URL is the default URL, do not show the loader.
   * If the redirect URL is not the default URL, show the loader for some time.
   */
  async redirect(){
    if(this.auth.redirectUrl!== DEFAULT_REDIRECT_TO){
      await this.alerts.showGlobalLoader('Redirecting to requested page...', 2000);
    }
    this.router.navigate([`/${this.auth.redirectUrl}`], { replaceUrl: true });
    this.auth.redirectUrl = '';
  }
  showResetForm() {
    this.showLoginForm = false;
    this.showResetPasswordForm = true;
  }
  sendResetRequest(e:any){
    this.auth.sendResetRequest(this.resetPasswordFormModel).then((res : any) => {
      this.alerts.showToast('Password reset request sent. Please check your email.', 'bottom', 3000, 'success');
      this.backToLogin();
    }).catch((err) => {
      this.alerts.showApiErrorToast(err, 'Invalid username or password');
    });
  }
  backToLogin(){
    this.showLoginForm = true;
    this.showResetPasswordForm = false;
  }
  checkGeneralNotice(){
    if(this.sharedVars.EXTRA_SERVER_SETTINGS){
      const generalNotice = this.sharedVars.EXTRA_SERVER_SETTINGS.find((setting: ExtraServerSetting) => setting.key === ExtraServerSettingKeys.GENERAL_NOTICE);
      if(generalNotice && generalNotice.text_value){
        this.alerts.showAlert('Announcement', generalNotice.text_value);
      }
    }
  }
  checkUserNotice(){
    if(this.sharedVars.EXTRA_SERVER_SETTINGS){
      const userNotice = this.sharedVars.EXTRA_SERVER_SETTINGS.find((setting: ExtraServerSetting) => setting.key === ExtraServerSettingKeys.USER_NOTICE);
      if(userNotice && userNotice.number_value === this.sharedVars.USER_DETAIL.id && userNotice.text_value){
        this.alerts.showAlert('Announcement', userNotice.text_value);
      }
    }
  }
}
