<ion-row>
  <ion-col class="ion-text-left">
    <h4>{{ props.label }}</h4>
  </ion-col>
</ion-row>
<ion-row *ngFor="let _field of field.fieldGroup; let i = index">
  <ion-col>
    <ion-row class="ion-align-items-center">
      <ion-col class="ion-text-left">
        <ion-text color="medium">Item {{ i + 1 }}</ion-text>
      </ion-col>
      <ion-col class="ion-text-right">
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="item-border">
        <formly-field [field]="_field"></formly-field>
      </ion-col>
    </ion-row>
  </ion-col>
</ion-row>
