<div *ngIf="resInvServ.selectedInvReserveRequest && resInvServ.selectedInvReserveRequest.id">
  <ion-row>
    <ion-col class="field-name ion-text-left" size="4">Project</ion-col>
    <ion-col class="field-value ion-text-left">{{resInvServ.selectedInvReserveRequest.project?.name}}</ion-col>
  </ion-row>
  <ion-row>
    <ion-col class="field-name ion-text-left" size="4">Inventory Out Request raised by</ion-col>
    <ion-col class="field-value ion-text-left">{{resInvServ.selectedInvReserveRequest.created_by.name}}</ion-col>
  </ion-row>
  <ion-row>
    <ion-col class="field-name ion-text-left" size="4">Inventory Out Request approved by</ion-col>
    <ion-col class="field-value ion-text-left">{{resInvServ.selectedInvReserveRequest.approval?.redirected_to ? resInvServ.selectedInvReserveRequest.approval?.redirected_to?.name : resInvServ.selectedInvReserveRequest.approval.approver.name}}</ion-col>
  </ion-row>
</div>
<ion-row>
  <ion-col class="ion-text-left">
    <h4>{{props.label}}</h4>
  </ion-col>
  <ion-col class="ion-text-right">
    <ion-button [disabled]="!isInvReserveReqSelected" (click)="addItem()" color="success">Add Item</ion-button>
  </ion-col>
</ion-row>
<ion-row *ngFor="let invReserveReqItem of field.fieldGroup; let i = index" >
  <ion-col>
    <ion-row class="ion-align-items-center">
      <ion-col class="ion-text-left">
        <ion-text color="medium">Item {{ i + 1 }}</ion-text>
      </ion-col>
      <ion-col class="ion-text-right">
        <ion-button *ngIf="resInvServ.showDeleteButton" (click)="removeItem(i)" color="danger" fill="clear">
          <ion-icon name="trash"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row class="quote-item">
      <ion-row *ngIf="resInvServ.selectedInvReserveRequestItem.length && resInvServ.selectedInvReserveRequestItem[i].id" class="ion-no-padding read-only-details">
        <ion-col class="ion-no-padding left-section">
          <ion-row>
            <ion-col class="section-title ion-text-left"> Inventory Reserve Request Item Details
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="field-name" size="5">Material</ion-col>
            <ion-col class="field-value clickable link ion-text-left">{{resInvServ.selectedInvReserveRequestItem[i].material.name}}</ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="field-name" size="5">MMID</ion-col>
            <ion-col class="field-value clickable link ion-text-left">{{resInvServ.selectedInvReserveRequestItem[i].mmid?.name}}</ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="field-name" size="5">Quantity</ion-col>
            <ion-col class="field-value ion-text-left">{{resInvServ.selectedInvReserveRequestItem[i].quantity}}
              {{resInvServ.selectedInvReserveRequestItem[i].material.unit}}</ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="field-name" size="5">Vendor</ion-col>
            <ion-col class="field-value clickable link ion-text-left">{{resInvServ.selectedInvReserveRequestItem[i].vendor?.name}}</ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-col>
        <formly-field [field]="invReserveReqItem"></formly-field>
      </ion-col>
    </ion-row>
  </ion-col>
</ion-row>
