import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule, PopoverController } from '@ionic/angular';
import { DropdownSearchPipe } from '@app/global/pipes/dropdown-search'; 
import { TippyDirective } from '@ngneat/helipopper';
import { CustomSelectOption } from '@app/global/models/basic';

@Component({
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, DropdownSearchPipe, TippyDirective],
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss'],
})
export class SelectSearchComponent implements OnInit {
  @Input() title: string = ""
  @Input() items: CustomSelectOption[] = [] as any;
  @Input() selected: string = "";
  itemsVisible: any = [];

  constructor(
    private popoverController: PopoverController) { }

  ngOnInit() {
    this.itemsVisible = this.items;
  }

  selectItem(item: any) {
    // If the item is already selected, close the popover
    if(this.selected && this.selected.toLowerCase()===item.label.toLowerCase()){
      this.popoverController.dismiss();
      return;
    }
    this.popoverController.dismiss({
      'selectedItem': item
    });
  }

  filterResults(e:any) {
    this.itemsVisible = this.items.filter((item: any) => {
      return item.label.toLowerCase().includes(e.detail.value.toLowerCase()) 
        || item.subLabel.toLowerCase().includes(e.detail.value.toLowerCase());
    })
  }
}
