import { Component, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
@Component({
  selector: 'app-onboarding-wrapper',
  templateUrl: './onboarding-wrapper.component.html',
  styleUrls: ['./onboarding-wrapper.component.scss'],
})
export class OnboardingWrapperComponent extends FieldWrapper  {

}
