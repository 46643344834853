<ion-row>
  <ion-col>
    <div class="identifier-input" *ngIf="prefix">
      <ion-label>{{prefix}}</ion-label>
      <ion-input 
      type="tel" 
      maxlength="4"
      [disabled]="props.disabled"
      [value]="identifier"
      (keydown)="onKeyDown($event)"
      (ionChange)="onIdentifierChange($event)"
      ></ion-input>
    </div>
    <ion-text color="medium" *ngIf="!prefix">{{props.placeholder}}</ion-text>

  </ion-col>
</ion-row>