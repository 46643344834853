import { Injectable } from '@angular/core';
import { SharedVarsService } from '@app/global/services/shared-vars.service';
import { CrudService } from './crud.service';
@Injectable({
  providedIn: 'root'
})
export class QuoteService {
  public static TOTAL_QUOTES = 0;
  public static SAVED_QUOTE = 0;
  IS_APPROVER_ASSIGNED = false;
  IS_APPROVER = false;
  IS_PURCHASE_REQUEST_CANCELLED = false;
  IS_SERVICE_REQUEST_CANCELLED = false;
  ACTIVE_PURCHASE_REQUEST_ITEM = null;
  ACTIVE_SERVICE_REQUEST_ITEM = null;
  constructor(private sharedVars: SharedVarsService,
    private crud: CrudService) { }

  getQuoteGroupDetailByRequestItemId(purchase_request_item_id?: number, service_request_item_id?: number){
    return new Promise((resolve, reject) => {
      let options:any = {};
      if (purchase_request_item_id) {
        options['purchase_request_item'] = purchase_request_item_id;
      }
      if (service_request_item_id) {
        options['service_request_item'] = service_request_item_id;
      }
      this.crud.get('quotegroups', '', options).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  getQuoteGroupDetailById(id: number){
    return new Promise((resolve, reject) => {
      this.crud.get('quotegroups', id).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  createQuoteGroup(quoteGroup: any){
    return new Promise((resolve, reject) => {
      this.crud.post('quotegroups', quoteGroup, {key: 'approval', value: false}).then((res) => {
        if (res.status === 201) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  updateQuoteGroup(quoteGroup: any, id: number, approval: boolean){
    return new Promise((resolve, reject) => {
      const options: any = {};
      if (approval) {
        options['approval'] = approval;
      }
      this.crud.put(`quotegroups`, id, quoteGroup, options).then((res) => {
        if (res.status === 201) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  /**
   * Approve the Quote via patch request.
   * @param id 
   * @returns 
   */
  approveQuote(id: number){
    return new Promise((resolve, reject) => {
      this.crud.patch('quotes', id, {selected: true}).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    })
  }
}
