import { Injectable } from '@angular/core';
import {
  MaterialRequest,
  Material,
} from '@app/global/models/basic';
import { CrudService } from '@app/global/services/crud.service';
import { MaterialRequestStatus } from '../models/enums';

@Injectable({
  providedIn: 'root',
})
export class MaterialRequestsService {
  statusColor: any = {
    [MaterialRequestStatus.OPEN]: 'danger',
    [MaterialRequestStatus.AVAILABLE]: 'success',
    [MaterialRequestStatus.PROCESSED]: 'warning',
    [MaterialRequestStatus.CLOSED]: 'medium',
  }
  
  constructor(
    private crud: CrudService,
  ) {}

/**
 * Get all approved material request items for a project
 * and a specific material. 
 * @param projectId 
 * @param materialId 
 * @returns 
 */
  getMaterialRequestItemsForProject(projectId: number, materialId?: any): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.crud.get('materialrequestitems', '', {project: projectId, material: materialId}).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  /**
   * Get all material requests for the current user.
   * If approverID is provided, then it will return
   * all requests to be approved by the user/approver.
   * If approverID is null, then it will return all requests
   * based on the user's role.
   * @param approver - Approver ID
   * @param page - Requested page number
   * @returns
   */
  getAllMaterialRequests(
    page: number,
    sortText?: string,
    searchText?: string,
    filters?: any[], 
  ): Promise<MaterialRequest[]> {
    return new Promise((resolve, reject) => {
      let params:any = {
        ordering: sortText,
        search: searchText 
      }
      if (filters && filters.length > 0) {
        filters.forEach((filter) => {
          params[filter.key] = filter.value;
        });
      }
      this.crud
        .list('materialrequests', page, params)
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getMaterialRequestDetails(id: number): Promise<MaterialRequest> {
    return new Promise((resolve, reject) => {
      this.crud
        .get('materialrequests', id)
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createMaterialRequest(request: MaterialRequest) {
    return new Promise((resolve, reject) => {
      this.crud
        .post('materialrequests', request)
        .then((res) => {
          if (res.status === 201) {
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateMaterialRequest(requestId: number, request: MaterialRequest) {
    return new Promise((resolve, reject) => {
      this.crud
        .put('materialrequests', requestId, request)
        .then((res) => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  cancelMaterialRequest(requestId: number) {
    return new Promise((resolve, reject) => {
      this.crud
        .patch('materialrequests', requestId, { is_canceled: true })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  cancelMaterialRequestItem(itemId: number) {
    return new Promise((resolve, reject) => {
      this.crud
        .put('materialrequestitems', itemId, { is_canceled: true})
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  downloadCsv() {
    return new Promise((resolve, reject) => {
      this.crud
        .getCsv('materialrequestcsv')
        .then((res) => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
