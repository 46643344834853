import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { AppFormlyConfigBuilder } from '@app/global/models/custom-builder';
import { buildConfig } from '@app/global/models/formly-builder';

// Include type safety for formly
// https://www.educative.io/answers/how-to-generate-type-safe-custom-builders-for-angular-formly-lib

@Component({
  selector: 'custom-form',
  templateUrl: './custom-form.component.html',
  styleUrls: ['./custom-form.component.scss'],
})
export class CustomFormComponent implements OnInit {
  @Input() formData: any;
  @Input() formModel: any;
  @Input() class: string;
  @Input() submitButton: boolean = true;
  @Input() submitButtonText: string = 'Submit';
  @Input() formId: string;
  @Input() submitButtonId: string = 'submit-button';
  @Input() description: string;
  @Input() spinner: boolean = false;
  @Input() disabled: boolean = false;
  @Input() submitOnEnter: boolean = false;
  @Output() formSubmit = new EventEmitter<any>();
  form = new FormGroup({});
  fb = new AppFormlyConfigBuilder<any>();

  @Input() options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [];
  //   // Notice how you get auto-complete of the correct types from the model
  //   this.fb.input({ key: "name" }),

  //   // Differenet approaches with different levels of type strictness
  //   // this.fb.build({ key: "age" }), // only keyOf restriction
  //   // this.fb.build<number>({ key: "age" }), // keyOf + number
  //   this.fb.number({ key: "age" }), // keyOf + number

  //   // Use standalone function
  //   buildConfig<FormModel, number>({ key: "age" }),

  //   this.fb.date({ key: "dob" }),
  //   // this.fb.build({ key: "dob" }),
  //   // this.fb.build<Date>({ key: "dob" }),

  //   this.fb.checkbox({
  //     key: "isSomething",
  //     props: { label: "Loves Life" },
  //     defaultValue: true
  //   }),

  //   this.fb.textArea({ key: "description" }),

  //   // Can even setup helpers that are typed on an object
  //   this.fb.subProp({ key: "address" })
  // ];
  constructor() {}

  ngOnInit() {
    for (let key in this.formData) {
      const fieldData = this.formData[key];
      const fieldType = fieldData.type;
      if(!fieldType || 
        fieldType === 'repeat' || 
        fieldType === 'purchase_request_item' ||
        fieldType === 'material_request_item' ||
        fieldType === 'inventory_out_request_item' ||
        fieldType === 'quote' || 
        fieldType === 'purchase_item' || 
        fieldType === 'purchase_order_quote' || 
        fieldType === 'inventory_in_item' || 
        fieldType === 'service_request_item' || 
        fieldType === 'inventory_out_item' || 
        fieldType === 'reserved_inv_item' || 
        fieldType === 'leave_balance_item' ||
        fieldType === 'project_personnel_wrapper') {
        this.fields.push(this.fb.subProp(fieldData));
      }
      else{
        this.fields.push(this.fb[fieldType as keyof typeof this.fb](fieldData));
      }
    }

    if (this.disabled) {
      for (let field of this.fields) {
        field.expressions = {
          'props.disabled': 'true',
        }
      }
    }
  }
  onSubmit(model: any) {
    if (this.form.valid) {
      this.formSubmit.emit(model);
    }
  }
  onEnter(model: any, event: any) {
    if (this.submitOnEnter) {
      event.preventDefault();
      this.onSubmit(model);
    }
  }
}
