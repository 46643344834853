import { RequestItemOption } from '@app/global/models/basic';

export const sideMenuPages = {
  '': [
    { title: 'Dashboard', url: 'dashboard', icon: 'desktop', disabled: false},
    { title: 'Projects', url: 'projects', icon: 'archive', disabled: false },
    { title: 'Business Devp.', url: 'bd', icon: 'airplane', disabled: true },
    { title: 'Reports', url: 'reports', icon: 'stats-chart', disabled: true  },
    { title: 'Purchase Orders', url: 'purchase-orders', icon: 'documents', disabled: false },
    { title: 'Payments', url: 'payments', icon: 'cash', disabled: false },
  ],
  'REQUESTS': [
    { title: 'Material Request', url: 'material-requests', icon: 'cube', disabled: false },
    { title: 'Purchase Request', url: 'purchase-requests', icon: 'cart', disabled: false },
    { title: 'Service Request', url: 'service-requests', icon: 'build', disabled: false },
    { title: 'Inventory Out Request', url: 'inventory-out-requests', icon: 'cloud-upload', disabled: false },
  ],
  'SUPPLY CHAIN': [
    { title: 'Inventory IN', url: 'inventory-ins', icon: 'cloud-download', disabled: false },
    { title: 'Inventory OUT', url: 'inventory-outs', icon: 'cloud-upload', disabled: false },
    { title: 'Reserved Inventory', url: 'reserved-inventory', icon: 'bag-check', disabled: false },
    { title: 'Vendors', url: 'vendors', icon: 'cart', disabled: false },
    { title: 'Manufacturers', url: 'manufacturers', icon: 'people', disabled: false },
    { title: 'Materials', url: 'materials', icon: 'cube', disabled: false },
    { title: 'MMIDs', url: 'mmids', icon: 'apps', disabled: false },
    { title: 'Parts', url: 'parts', icon: 'extension-puzzle', disabled: false },
  ],
  HR: [
    { title: 'Users', url: 'users', icon: 'person', disabled: false },
    // { title: 'Leaves', url: 'leaves', icon: 'leaf' },
    { title: 'Attendance', url: 'attendance', icon: 'calendar-number', disabled: true  },
  ],
};



export const CreateMaterialOptions: RequestItemOption[] = [
  {
    text: 'Create New Material',
    key: 'createNewMaterial',
    disabled: true,
  },
  {
    text: 'Add Existing Material',
    key: 'addExistingMaterial',
    disabled: false,
  }
];


export const AddMmidOptions: RequestItemOption[] = [
  {
    text: 'Create New MMID',
    key: 'createNewMmid',
    disabled: true,
  },
  {
    text: 'Add Existing MMID',
    key: 'addExistingMmid',
    disabled: false,
  }
];

export const AddManufacturerOptions: RequestItemOption[] = [
  {
    text: 'Add New Manufacturer',
    key: 'addNewManufacturer',
    disabled: true,
  },
  {
    text: 'Add Existing Manufacturer',
    key: 'addExistingManufacturer',
    disabled: false,
  }
];

export const AddVendorOptions: RequestItemOption[] = [
  {
    text: 'Add New Vendor',
    key: 'addNewVendor',
    disabled: true,
  },
  {
    text: 'Add Existing Vendor',
    key: 'addExistingVendor',
    disabled: false,
  }
];

export const MmidItemOptions: RequestItemOption[] = [
  {
    text: 'Add New Vendor',
    key: 'addNewVendor',
    disabled: true,
  },
  {
    text: 'Add Existing Vendor',
    key: 'addExistingVendor',
    disabled: false,
  },
  {
    text: 'Remove Vendor',
    key: 'removeVendor',
    disabled: false,
  },
  {
    text: 'Remove Manufacturer/MMID',
    key: 'removeManufacturer',
    disabled: false,
  }
];

export const Departments = [
  { name: 'Supply Chain', value: 'supply_chain', key:'SCM' },
  { name: 'HR', value: 'hr', key:'HR' },
  { name: 'Business Development', value: 'business_development', key:'BD' },
  { name: 'Finance', value: 'finance', key:'FIN' },
  { name: 'Admin', value: 'admin', key:'ADM' },
  { name: 'Design', value: 'design', key:'DSG' },
  { name: 'Production', value: 'production', key:'PROD' },
  { name: 'Quality', value: 'quality', key:'QLY' },
  { name: 'Planning', value: 'planning', key:'PLN' },
  { name: 'Operations', value: 'operations', key:'OPR' },
];