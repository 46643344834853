import { Component, OnInit } from '@angular/core';
import { AlertsService } from '@app/global/services/alerts.service';
import { ReserveInventoryService } from '@app/global/services/reserve-inventory.service';
import { FieldArrayType } from '@ngx-formly/core';


@Component({
  selector: 'app-reserved-inventory-item',
  templateUrl: './reserved-inventory-item.component.html',
  styleUrls: ['./reserved-inventory-item.component.scss'],
})
export class ReservedInventoryItemComponent extends FieldArrayType implements OnInit {
  isInvReserveReqSelected = false;
  constructor(
    public resInvServ: ReserveInventoryService,
    private alerts: AlertsService,
    ) {
    super();
  }
  ngOnInit() {
    this.resInvServ.isReservedInvReqSelected.subscribe((updatedValue) => {
      this.isInvReserveReqSelected = updatedValue;
    });
  }

  addItem() {
    this.resInvServ.selectedInvReserveRequestItem.push({});
    this.add();
  }

  removeItem(itemIndex: number) {
    if (this.resInvServ.showDeleteAlert) {
      this.showDeleteItemPrompt(itemIndex);
    } else {
      this.removeField(itemIndex);
    }
  }

  async removeField(i: number) {
    const decision = await this.alerts.showPromisableAlert(
      'Remove Item',
      'Are you sure you want to remove this item from the request?',
      true,
      'Remove',
      'Cancel',
      'danger',
      'medium'
    );
    if (decision) {
      this.resInvServ.selectedInvReserveRequestItem.splice(i, 1);
      this.remove(i);
    }
  }

  async showDeleteItemPrompt(itemIndex: number) {
    const decision = await this.alerts.showPromisableAlert(
      'Delete Item',
      'Deleting this item will remove the quantity from records, and mark the related Purchase item undelivered. Are you sure you want to remove this item ?',
      true,
      'Delete',
      'Cancel',
      'danger',
      'medium'
    );
    if (decision) {
      this.resInvServ.deleteReservedInventory(this.resInvServ.selectedReservedInventory).then(() => {
        this.alerts.showToast('Deleted the Inventory In Succesfully');
        this.resInvServ.selectedInvReserveRequestItem = [];
      });
    }
  }

  ngOnChanges() {
  }


}
