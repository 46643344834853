import { Injectable, inject } from '@angular/core';
import { CanActivate, CanActivateFn } from '@angular/router';
import { AuthService } from '@app/global/services/auth.service';
import { SharedVarsService } from '@app/global/services/shared-vars.service';
import { SessionService } from '@app/global/services/session.service';
import { AlertsService } from '@app/global/services/alerts.service';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
/**
 * This guard is used to check if the user is logged in and
 * if the session data is available.
 * If the user is logged in, he will be redirected to the projects page.
 */
export class GuardsService implements CanActivate {
  constructor(
    private auth: AuthService,
    private sharedVars: SharedVarsService,
    private sessionServ: SessionService,
    private alerts: AlertsService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try{ // if logged in, redirect to projects page
      await this.auth.checkAuth(state.url);
      this.sessionServ.startSession();
      this.sharedVars.HIDE_MENU = false;
      return true;
    }
    catch(err){ // if not logged in, show login page
      this.sessionServ.stopSession();
      return false;
    }
  }
}

/**
 * Restrict access to modules that are not available in current version
 * @returns false
 */
export const DisabledModules : CanActivateFn = () => {
  const sharedVars = inject(SharedVarsService);
  if (sharedVars.DEV_MODE){
    return true;
  }
  const alerts = inject(AlertsService);
  alerts.showAlert('Please note', 'This module is not available in current version.');
  return false;
}
