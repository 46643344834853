import { Component, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'app-leave-balance-item',
  templateUrl: './leave-balance-item.component.html',
  styleUrls: ['./leave-balance-item.component.scss'],
})
export class LeaveBalanceItemComponent extends FieldArrayType implements OnInit {
  
  ngOnInit() {}

}
