import { Injectable } from '@angular/core';
import { SharedVarsService } from '@app/global/services/shared-vars.service';
import { LoginForm } from '@app/global/models/formly-forms';
import { CrudService } from './crud.service';
import { UserDetail } from '@app/global/models/basic';
import { Router } from '@angular/router';
import { ExtraServerSetting } from '@app/global/models/basic';
import { GeneralService } from './general.service';
import { DEFAULT_REDIRECT_TO } from '@app/global/constants/constants';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loggedIn = false; // Shared variable for login status
  redirectUrl: string = ''; // Redirect URL after login
  constructor(
    private sharedVars: SharedVarsService,
    private crud: CrudService,
    private router: Router,
    private general: GeneralService
  ) {}
  /**
   * Check if the user is logged in.
   * Check if the initial app data is available.
   * If the user is logged in, he will be redirected to the projects page.
   * If the user is not logged in, he will be redirected to the login page.
   * If the user is logged in but the session data is not available,
   * he will be redirected to the login page without calling the logout API.
   * @returns -
   */
  checkAuth(url:string =''): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (this.sharedVars.MAINTENANCE_MODE) {
        reject(false);
      }
      let userDetail: any;
      try {
        userDetail = await this.crud.get('whoami');
        const dataLoaded = await this.general.loadInitialApiData();
        this.loggedIn = true;
        this.sharedVars.USER_DETAIL = userDetail.data;
        resolve(true);
      } catch (err) {
        if (window.location.pathname.indexOf('password-reset-confirm') === -1) {
          this.redirectUrl = url ? url : DEFAULT_REDIRECT_TO;
          userDetail ? this.logout() : this.afterLogout();
          // this.router.navigate(['/login']);
        }
        reject(false);
      }
    });
  }

  ping(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.crud.get('whoami');
        resolve(true);
      } catch (err) {
        reject(false);
      }
    });
  }

  /**
   * Call after the user is logged out.
   * Reset the shared variables and redirects to the login page.
   * If the redirect URL is available, and it is not the default 
   * redirect URL, add it to the query params.
   */
  afterLogout() {
    const routeExtras:any = { replaceUrl: true };
    if (this.redirectUrl && this.redirectUrl !== DEFAULT_REDIRECT_TO) {
      routeExtras.queryParams = { re: this.redirectUrl };
    }
    this.router.navigate(['/login'], routeExtras);
    this.loggedIn = false;
    this.sharedVars.USER_DETAIL = {} as UserDetail;
    this.sharedVars.MATERIAL_UNITS = [];
    this.sharedVars.MATERIAL_CATEGORIES = [];
    this.sharedVars.LEAVE_TYPES = [];
    this.sharedVars.DEPARTMENTS = [];
  }

  /**
   * Login the user.
   * @param credentials 
   * @returns 
   */
  login(credentials: LoginForm) {
    return new Promise((resolve, reject) => {
      this.crud
        .post('account/login', credentials)
        .then(async (res) => {
          if (res.status === 200) {
            this.sharedVars.USER_DETAIL = res.data;
            this.loggedIn = true;
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Logout the user.
   * @returns 
   */
  logout() {
    return new Promise((resolve, reject) => {
      this.crud
        .get('account/logout')
        .then((res) => {
          if (res.status === 200) {
            resolve(true);
          }
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          this.afterLogout();
        });
    });
  }
  /**
   * Send a password reset request.
   * @param data - email
   * @returns 
   */
  sendResetRequest(data: any) {
    return new Promise((resolve, reject) => {
      this.crud
        .post('password-reset', data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  /**
   * Reset the password.
   * @param url - password-reset-confirm/uid/token
   * @param data - new password
   * @returns 
   */
  resetPassword(url: string, data: any) {
    return new Promise((resolve, reject) => {
      this.crud
        .put(url, null, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
