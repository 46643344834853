import { Component, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-cost-wrapper',
  templateUrl: './cost-wrapper.component.html',
  styleUrls: ['./cost-wrapper.component.scss'],
})
export class CostWrapperComponent extends FieldWrapper {

}
