import { Component, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import { AlertsService } from '@app/global/services/alerts.service';
@Component({
  selector: 'app-project-personnel-wrapper',
  templateUrl: './project-personnel-wrapper.component.html',
  styleUrls: ['./project-personnel-wrapper.component.scss'],
})
export class ProjectPersonnelWrapperComponent extends FieldArrayType implements OnInit {

  constructor(
    private alerts: AlertsService,
    ) {
    super();
  }
  ngOnInit() {
  }

  addItem() {
    this.add();
  }

  removeItem(itemIndex: number) {
    this.remove(itemIndex);
  }

  ngOnChanges() {
  }

}