import { Component, OnInit } from '@angular/core';
import { AlertsService } from '@app/global/services/alerts.service';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'app-material-request-item',
  templateUrl: './material-request-item.component.html',
  styleUrls: ['./material-request-item.component.scss'],
})
export class MaterialRequestItemComponent extends FieldArrayType {
  advanced:number = -1;
  constructor(private alerts: AlertsService) {
    super();
  }
  async removeField(i: number) {
    const decision = await this.alerts.showPromisableAlert(
      'Delete Material',
      'Are you sure you want to remove this item from the request?',
      true,
      'Remove',
      'Cancel',
      'danger',
      'medium'
    );
    if (decision) {
      this.remove(i);
    }
  }
  toggleAdvanced(index:number){
    this.advanced = this.advanced === index ? -1 : index;
  }
}
