import { Injectable } from '@angular/core';
import { ReservedInventory } from '@app/global/models/basic';
import { CrudService } from '@app/global/services/crud.service';
import { BehaviorSubject } from 'rxjs';
import { ReservedInventoryItemForm } from '@app/global/models/formly-forms';

@Injectable({
  providedIn: 'root'
})
export class ReserveInventoryService {
  public isReservedInvReqSelected = new BehaviorSubject(false);
  public showDeleteAlert = false;
  public showDeleteButton = true;
  public selectedReservedInventory = 0;
  public selectedInvReserveRequestItem: any = [];
  public selectedInvReserveRequest: any = {};

  constructor(private crud: CrudService) {}

  getReservedInventoryById(resInvId: number): Promise<ReservedInventory>{
    return new Promise((resolve, reject) => {
      this.crud.get('reserved', resInvId).then((res) => {
        if (res.status === 200) {
          resolve(res.data)
        }
      }).catch((err) => {
        reject(err);
      });
    });
  }

  getAllReservedInventory(page: number,
    sortText?: string,
    searchText?: string,
    filters?: any[]){
    return new Promise((resolve, reject) => {
      let params:any = {
        ordering: sortText,
        search: searchText 
      }
      if (filters && filters.length > 0) {
        filters.forEach((filter) => {
          params[filter.key] = filter.value;
        });
      }
      this.crud.list('reserved', page, params).then((res) => {
        if (res.status === 200) {
          resolve(res.data)
        }
      }).catch((err) => {
        reject(err);
      });
    });
  }

 createReservedInventory(reserveInventoryData: ReservedInventoryItemForm){
    return new Promise((resolve, reject) => {
      this.crud.post('reserved', reserveInventoryData).then((res) => {
        if (res.status === 201) {
          resolve(res)
        }
      }).catch((err) => {
        reject(err);
      });
    }); 
  }


  deleteReservedInventory(resInvId: number) {
    return new Promise((resolve, reject) => {
      this.crud.patch('reserved', resInvId, {is_deleted: true}).then((res) => {
        if (res.status === 201) {
          resolve(res)
        }
      }).catch((err) => {
        reject(err);
      });
    }); 
  }

  updateReservedInventory(resInvId: number, reserveInventoryData: any) {
    return new Promise((resolve, reject) => {
      this.crud.put('reserved', resInvId, reserveInventoryData).then((res) => {
        if (res.status === 200) {
          resolve(res)
        }
      }).catch((err) => {
        reject(err);
      });
    });
  }

  cancelReservedInventory(resInvId: number) {
    return this.updateReservedInventory(resInvId, {is_canceled: true});
  }
  unreserveInventory(resInvId: number) {
    return new Promise((resolve, reject) => {
      this.crud.get(`reserved/${resInvId}/unreserve`).then((res) => {
        if (res.status === 200) {
          resolve(res)
        }
      }).catch((err) => {
        reject(err);
      });
    });
  }
}
