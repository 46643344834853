import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  FormlyConfigBuilder,
  FormlyFieldConfigKeyed,
} from '@app/global/models/formly-builder';
import { SubModel } from '@app/global/models/formly-forms';

/** Example custom builder demonstrating how to take advantage of the FormlyConfigBuilder. */
export class AppFormlyConfigBuilder<T> extends FormlyConfigBuilder<T> {
  // Setup label if it is not defined
  private applyDefaultLabel<S>(fieldConfig: FormlyFieldConfigKeyed<T, S>) {
    return this.build({
      ...fieldConfig,
      props: {
        label: camelToTitle(fieldConfig.key),
        ...fieldConfig.props,
      },
    });
  }

  input(fieldConfig: FormlyFieldConfigKeyed<T, string>): FormlyFieldConfig {
    return this.applyDefaultLabel({ type: 'input', ...fieldConfig });
  }

  number(fieldConfig: FormlyFieldConfigKeyed<T, number>): FormlyFieldConfig {
    return this.applyDefaultLabel({
      type: 'input',
      ...fieldConfig,
      props: { type: 'number', ...fieldConfig.props },
    });
  }

  textarea(fieldConfig: FormlyFieldConfigKeyed<T, string>): FormlyFieldConfig {
    return this.applyDefaultLabel({ type: 'textarea', ...fieldConfig });
  }

  datetime(fieldConfig: FormlyFieldConfigKeyed<T, Date>): FormlyFieldConfig {
    return this.applyDefaultLabel({
      type: 'datetime',
      ...fieldConfig,
      props: { ...fieldConfig.props },
    });
  }

  checkbox(fieldConfig: FormlyFieldConfigKeyed<T, boolean>): FormlyFieldConfig {
    return this.applyDefaultLabel({ type: 'checkbox', ...fieldConfig });
  }

  select(fieldConfig: FormlyFieldConfigKeyed<T, string>): FormlyFieldConfig {
    return this.applyDefaultLabel({ type: 'select', ...fieldConfig });
  }

  customselect(fieldConfig: FormlyFieldConfigKeyed<T, string>): FormlyFieldConfig {
    return this.applyDefaultLabel({ type: 'customselect', ...fieldConfig });
  }

  identifier(fieldConfig: FormlyFieldConfigKeyed<T, string>): FormlyFieldConfig {
    return this.applyDefaultLabel({ type: 'identifier', ...fieldConfig });
  }

  file(fieldConfig: FormlyFieldConfigKeyed<T, string>): FormlyFieldConfig {
    return this.applyDefaultLabel({ type: 'file', ...fieldConfig });
  }

  subProp(fieldConfig: FormlyFieldConfigKeyed<T, SubModel>): FormlyFieldConfig {
    // Get type safety for sub form model here
    const subBuilder = new AppFormlyConfigBuilder<SubModel>();
    return {
      props: { label: 'Sub Form' },
      ...fieldConfig,
    };
  }
}

const camelToTitle = (camelCase?: string) =>
  camelCase
    ?.replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();
