import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from '@app/app.component';
import { AppRoutingModule } from '@app/app-routing.module';
import { HeaderModule } from '@app/global/modules/header.module';
import { CustomFormModule } from './global/modules/custom-form/custom-form.module';
import { StartupService } from './global/services/startup.service';
import { SessionService } from './global/services/session.service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'
import { HttpClientModule } from '@angular/common/http';
import { provideTippyConfig, tooltipVariation, popperVariation, TippyDirective } from '@ngneat/helipopper';

export function initConfiguration(startupService: StartupService) {
  startupService.getCsrfToken();
  startupService.getServerSettings();
  return () => true;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    HeaderModule,
    CustomFormModule,
    NgIdleKeepaliveModule.forRoot(),
    HttpClientModule
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    StartupService,
    SessionService,
    provideTippyConfig({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
      }
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: initConfiguration,
      multi: true,
      deps: [StartupService]
    },],
  bootstrap: [AppComponent],
})
export class AppModule {}
