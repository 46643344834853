import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-identifier',
  templateUrl: './identifier.component.html',
  styleUrls: ['./identifier.component.scss'],
})
export class IdentifierComponent extends FieldType<FieldTypeConfig> implements OnInit {
  identifier: number;
  prefix: string = '';
  constructor() {
    super();
  }

  ngOnInit() {
    if (this.formControl?.value) {
      let idf = this.formControl?.value.split('-');
      this.prefix = idf.slice(0, -1).join('-') + '-';
      this.identifier = idf[idf.length - 1];
    }
  }

  onKeyDown(event: any) {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete'];
    const isDigit = event.key >= '0' && event.key <= '9';
    
    if (!isDigit && !allowedKeys.includes(event.key)) {
      event.preventDefault(); // Prevent the non-numeric key press
    }
  }

  onIdentifierChange(event: any) {
    this.formControl.markAsDirty();
    this.identifier = event.target.value;

    const full_identifier = this.prefix + this.identifier.toString().padStart(4, '0');
    this.formControl.setValue(full_identifier);
  }

}
