import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { AlertsService } from '@app/global/services/alerts.service';
import { GeneralService } from '@app/global/services/general.service';
@Component({
  standalone: true,
  imports: [CommonModule, IonicModule],
  selector: 'docs-wrapper',
  templateUrl: './docs-wrapper.component.html',
  styleUrls: ['./docs-wrapper.component.scss'],
})
export class DocsWrapperComponent implements OnInit {
  @Input() canAddDocs: boolean;
  @Input() canEditDocs: boolean;
  @Input() title: string;
  @Input() docs: any;
  @Input() type: string = 'general';
  @Input() parentId: number; // Parent where docs are uploaded
  @Input() model: string; // Model where docs are uploaded
  @Input() layout: string = 'list'; // list or card
  @Input() childIndex: number = 0; // Including this for cases where docs are uploaded in an ngFor child component
  @Output() afterUpload = new EventEmitter<any>();
  @ViewChild('docUpload', { static: false }) DocFileInputVariable: ElementRef;
  @Output() afterRemove = new EventEmitter<any>();
  uploadLoader: boolean = false;
  constructor(private alerts: AlertsService, private general: GeneralService) {}

  ngOnInit() {}
  
  async deleteFileEvent(id: number) {
    const decision = await this.alerts.showPromisableAlert(
      'Delete uploaded document',
      'This action is irreversible. Are you sure you want to delete this document?',
      true,
      'Delete',
      'Cancel',
      'danger',
      'medium'
    );
    if (decision) {
      this.removeDoc(id);
    }
  }
  handleDocUpload(e: any) {
    this.uploadLoader = true;
    var doc = e.target.files[0];
    if (doc && doc.size > 5000000) {
      this.alerts.showToast('File size should not exceed 5MB', 'bottom', 3000, 'danger');
      this.uploadLoader = false;
      return;
    }
    this.DocFileInputVariable.nativeElement.value = '';
    this.general
      .uploadFileToModel(doc, this.parentId, this.model, this.type)
      .then((res: any) => {
        this.alerts.showToast('Document uploaded successfully');
        this.afterUpload.emit(res.data);
        this.uploadLoader = false;
      })
      .catch((err) => {
        this.uploadLoader = false;
        this.alerts.showToast('Error uploading document');
      });
  }
  removeDoc(id: number){
    this.general.removeFile(id).then((res) => {
      this.alerts.showToast('Document removed successfully');
      this.afterRemove.emit(id);
    });
  }
}
